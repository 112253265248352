import React, { useState } from 'react';
import { useAuthInfo } from '@propelauth/react';
import Signup from './Signup';
import Login from './Login';
import './Home.css';
import './responsive.css';
import logo from './assets/images/cartracker298.png';
import ExtensionSection from './ExtensionSection';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from './assets/images/Animation-1724449776708.json';
import { Helmet } from 'react-helmet';
import LogoCarousel from './logoCarousel';

function Home({ onLogin }) {
  const [showLogin, setShowLogin] = useState(true);
  const { isLoggedIn } = useAuthInfo();

  const handleSwitchToLogin = () => {
    setShowLogin(true);
  };
  const handleSwitchToSignup = () => {
    setShowLogin(false);
  };

  return (
    <div className="home">
      <Helmet>
        <title>Cartracker | Home</title>
      </Helmet>
      <header className="home-header">
        <Link to="/">
          <img src={logo} alt="CarClump Logo" className="home-logo" />
        </Link>
        <h1>Car Tracker</h1>
        <div className="home-description">
          <h2>Consolidate Your Car Search Into One Site</h2>
          <p className="slogan">
            Click and save vehicles from any car site. Compare and view them all in your custom dashboard.
          </p>

          <div className="features-container"> {/* Use a simple container to display the features */}
            <div className="feature-box">
              <div className="color-bar color-bar-5"></div>
              <strong>Time-Saving:</strong>
              <p>No more juggling between tabs or losing track of that perfect car you saw yesterday.</p>
            </div>
            <div className="feature-box">
              <div className="color-bar color-bar-3"></div>
              <strong>Universal Compatibility:</strong>
              <p>Effortlessly track cars from multiple platforms, including Carfax, Cars.com, Facebook Marketplace, CarMax, and many more.</p>
            </div>
            <div className="feature-box">
              <div className="color-bar color-bar-2"></div>
              <strong>Smart Organization:</strong>
              <p>Automatically save and categorize the cars you've viewed, creating your personalized inventory.</p>
            </div>
            <div className="feature-box">
              <div className="color-bar color-bar-4"></div>
              <strong>Easy Comparison:</strong>
              <p>Compare your favorite cars side-by-side to make informed decisions.</p>
            </div>

          </div>
          <LogoCarousel />
          <ExtensionSection />
          <p className="car-tracker-summary">
            With Car Tracker, you're always in the driver's seat of your car shopping journey. Streamline your search, save time, and find your dream car with confidence. Start your smarter car shopping experience today!
          </p>
        </div>
      </header>

      <footer className="home-footer">
        <p>&copy; {new Date().getFullYear()} Car Tracker. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
