import React, { useState } from 'react';
import { updateProfile, updatePassword } from '../api'; // Import your custom API functions
import { Helmet } from 'react-helmet';

function Profile() {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = async (e) => {
    e.preventDefault();
    try {
      // Call the custom API function to update the profile
      await updateProfile({ email });
      setMessage('Email updated successfully!');
      setError('');
    } catch (err) {
      setError('Failed to update email.');
      setMessage('');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    try {
      // Call the custom API function to change the password
      await updatePassword({ currentPassword, newPassword });
      setMessage('Password updated successfully!');
      setError('');
    } catch (err) {
      setError('Failed to update password.');
      setMessage('');
    }
  };

  return (
    <div className="profile-container">
    <Helmet>
      <title>Cartracker | Profile</title>
    </Helmet>
      <h2>Profile Settings</h2>
      
      {/* Update Email */}
      <form onSubmit={handleEmailChange}>
        <div className="form-group">
          <label htmlFor="email">Update Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary">Update Email</button>
      </form>

      {/* Update Password */}
      <form onSubmit={handlePasswordChange} className="mt-4">
        <div className="form-group">
          <label htmlFor="currentPassword">Current Password</label>
          <input
            type="password"
            id="currentPassword"
            name="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary">Update Password</button>
      </form>

      {message && <div className="alert alert-success mt-3">{message}</div>}
      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </div>
  );
}

export default Profile;
