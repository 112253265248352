import axios from "axios";

// Base URL for the API, defaulting to localhost if not provided
const API_BASE_URL = process.env.REACT_APP_PROD_SERVER_URL;

// Create a function to create an Axios instance
const createApiInstance = (token) => {
  const apiInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true, // Necessary for cookie-based authentication
  });

  // Set the Authorization header with the token for all requests
  apiInstance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return apiInstance;
};

// Fetch function that includes credentials (for cookie-based auth)
const apiFetch = (url, options = {}) => {
  return fetch(`${API_BASE_URL}${url}`, {
    ...options,
    credentials: "include",
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
    },
  });
};

// User Authentication APIs

// // Register a new user
// export const signup = (userData, token) => {
//   const api = createApiInstance(token);
//   return api
//     .post("/register", userData) // Updated endpoint
//     .then((response) => response.data)
//     .catch((error) => {
//       console.error("Signup error:", error.response?.data || error.message);
//       throw error.response ? error.response.data : error;
//     });
// };

// // Log in an existing user
// export const login = (userData, token) => {
//   const api = createApiInstance(token);
//   return api
//     .post("/login", userData) // Updated endpoint
//     .then((response) => response.data)
//     .catch((error) => {
//       console.error("Login error:", error.response?.data || error.message);
//       throw error.response ? error.response.data : error;
//     });
// };

// // Fetch the current user's profile (if logged in)
// export const getProfile = (token) => {
//   const api = createApiInstance(token);
//   return api
//     .get("/auth/profile")
//     .then((response) => response.data)
//     .catch((error) => {
//       throw error.response ? error.response.data : error;
//     });
// };

// // Log out the current user
// export const logoutUser = (token) => {
//   const api = createApiInstance(token);
//   return api
//     .post("/auth/logout")
//     .then((response) => response.data)
//     .catch((error) => {
//       throw error.response ? error.response.data : error;
//     });
// };

// Car Data APIs

// Fetch all saved cars for the current user
export const fetchCars = (token) => {
  const api = createApiInstance(token);
  return api
    .get("/api/cars/get-saved-cars")
    .then((response) => response.data)
    .catch((error) => {
      throw error.response ? error.response.data : error;
    });
};

// Save car details for the current user
export const saveCar = (carData, token) => {
  const api = createApiInstance(token);
  return api
    .post("/cars/save-car-details", carData)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response ? error.response.data : error;
    });
};

// Upload an image and extract car details
export const uploadImage = (imageData, token) => {
  const api = createApiInstance(token);
  return api
    .post("/cars/upload", { image: imageData })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response ? error.response.data : error;
    });
};

// Extract car details from HTML content (e.g., from a scraped page)
export const extractCarDetails = (pageContent, token) => {
  const api = createApiInstance(token);
  return api
    .post("/cars/extract-car-details", { pageContent })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response ? error.response.data : error;
    });
};

// Scrape and analyze car data
export const scrapeAndAnalyze = (
  carDetails,
  carfaxReportUrl,
  pageContent,
  token
) => {
  const api = createApiInstance(token);
  return api
    .post("/cars/scrape-and-analyze", {
      carDetails,
      carfaxReportUrl,
      pageContent,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response ? error.response.data : error;
    });
};

export const deleteCar = async (carId, authToken) => {
  const response = await fetch(`/api/cars/${carId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete car");
  }
};

// Profile Management APIs

export const updateProfile = (profileData, token) => {
  const api = createApiInstance(token);
  return api
    .put("/auth/update-profile", profileData)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response ? error.response.data : error;
    });
};

export const updatePassword = (passwordData, token) => {
  const api = createApiInstance(token);
  return api
    .put("/auth/update-password", passwordData)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response ? error.response.data : error;
    });
};

// Check authentication status (useful for cookie-based auth)
export const checkAuthStatus = () => {
  return apiFetch("/api/auth-status")
    .then((response) => response.json())
    .catch((error) => {
      console.error("Auth status check error:", error);
      throw error;
    });
};

// Interceptor to handle authentication errors
const handleAuthError = (error) => {
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    console.log("Authentication error. Redirecting to login...");

    // Instead of dispatching a Redux action, we'll just redirect to the login page
    window.location.href = "/login";
  }
  return Promise.reject(error);
};

// Apply the interceptor to all created instances
axios.interceptors.response.use((response) => response, handleAuthError);

export default createApiInstance;
