import React from 'react';
import './Carousel.css'; // Import custom carousel styles
import carsLogo from './assets/images/logos/cars-logo.png'; // Example logo imports
import carfaxLogo from './assets/images/logos/carfax-logo.png';
import fbMarketplaceLogo from './assets/images/logos/facebook-logo.png';
import craigslistLogo from './assets/images/logos/craigslist-logo.png';
import carmaxLogo from './assets/images/logos/carmax-logo.png';
import autotraderLogo from './assets/images/logos/autotrader-logo.png';
import carvanaLogo from './assets/images/logos/carvana-logo.png';
import truecarLogo from './assets/images/logos/truecar-logo.png';

function LogoCarousel() {
    const logos = [
        { src: carsLogo, alt: 'Cars.com' },
        { src: carfaxLogo, alt: 'Carfax' },
        { src: fbMarketplaceLogo, alt: 'Facebook Marketplace' },
        { src: craigslistLogo, alt: 'Craigslist' },
        { src: carmaxLogo, alt: 'CarMax' },
        { src: autotraderLogo, alt: 'AutoTrader' },
        { src: carvanaLogo, alt: 'Carvana' },
        { src: truecarLogo, alt: 'TrueCar' },
    ];

    // Duplicate the logos for seamless looping
    const repeatedLogos = [...logos, ...logos];

    return (
        <div className="carousel">
            <div className="carousel-track">
                {repeatedLogos.map((logo, index) => (
                    <img
                        key={index}
                        src={logo.src}
                        alt={logo.alt}
                        className="carousel-logo"
                    />
                ))}
            </div>
        </div>
    );
}

export default LogoCarousel;
