import React from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "@propelauth/react"; // Ensure correct import
import "./index.css";
import "./components/responsive.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

// Ensure the container exists before creating the root
const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);

  // Check if environment variables are loaded properly
  const authUrl = process.env.REACT_APP_AUTH_URL;

  if (!authUrl) {
    console.error("Auth URL is missing. Check your environment variables.");
  }

  // Render the app inside AuthProvider
  root.render(
    <React.StrictMode>
      <AuthProvider authUrl={authUrl}>
        <Helmet>
          <title>Your App</title>
        </Helmet>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );

  reportWebVitals();
} else {
  console.error("Root container not found. Ensure index.html contains a <div id='root'></div>.");
}
