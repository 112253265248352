import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchCars, deleteCar } from '../api';
import './Dashboard.css';
import CarfaxAnalysis from './CarfaxAnalysis';

function Dashboard() {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [filters, setFilters] = useState({
    make: '',
    model: '',
    year: '',
    exterior_color: '',
    interior_color: '',
    vin: ''
  });

  const getSiteFromUrl = (url) => {
    try {
      const hostname = new URL(url).hostname;
      const domain = hostname.replace('www.', '');

      const siteMap = {
        'cars.com': 'Cars.com',
        'carfax.com': 'Carfax.com',
        'craigslist.org': 'Craigslist',
        'autotrader.com': 'AutoTrader',
        'cargurus.com': 'CarGurus',
        'edmunds.com': 'Edmunds',
        'ksl.com': 'KSL Cars',
        'facebook.com': 'Facebook Marketplace'
      };

      for (const [key, value] of Object.entries(siteMap)) {
        if (domain.includes(key)) return value;
      }
      return domain;
    } catch (error) {
      console.error('Error parsing URL:', error);
      return "N/A";
    }
  };

  const authToken = localStorage.getItem('jwtToken');
  //const BATCH_SIZE = 5;
  //const RETRY_DELAY = 2000;

  //  const checkCarAvailability = async (urls) => {
  //    const authToken = localStorage.getItem('jwtToken');
  //
  //    if (!urls || urls.length === 0) return {};
  //
  //    try {
  //      const response = await fetch('/api/cars/check-availability', {
  //        method: 'POST',
  //        headers: {
  //          'Content-Type': 'application/json',
  //          'Authorization': `Bearer ${authToken}`
  //        },
  //        body: JSON.stringify({ urls })
  //      });
  //
  //      if (!response.ok) {
  //        throw new Error(`HTTP error! status: ${response.status}`);
  //      }
  //
  //      const data = await response.json();
  //      return data.results.reduce((acc, result) => {
  //        if (result.hasOwnProperty('isAvailable')) {
  //          acc[result.url] = result.isAvailable;
  //        }
  //        return acc;
  //      }, {});
  //    } catch (error) {
  //      console.error('Error checking car availability:', error);
  //      return urls.reduce((acc, url) => {
  //        acc[url] = true;
  //        return acc;
  //      }, {});
  //    }
  //  };

  const loadCars = async () => {
    try {
      const carsData = await fetchCars(authToken);

      // Comment out availability checking but preserve for future use
      /*
      const carsWithPendingStatus = carsData.map(car => ({
        ...car,
        isAvailable: 'checking',
      }));
      setCars(carsWithPendingStatus);
      setFilteredCars(carsWithPendingStatus);
      */

      // Use direct data while availability checking is disabled
      setCars(carsData);
      setFilteredCars(carsData);

    } catch (error) {
      console.error('Failed to fetch cars', error);
      setCars([]);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filters, cars]);

  const [carfaxVins, setCarfaxVins] = useState(new Set());

  const fetchCarfaxVins = async () => {
    const authToken = localStorage.getItem('jwtToken');
    if (!authToken) {
      console.error('No auth token found');
      return;
    }

    try {
      console.log('Fetching Carfax VINs...'); // Add logging
      const response = await fetch('/api/car/carfax-vins', {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch Carfax VINs: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received Carfax VINs:', data.vins); // Add logging
      setCarfaxVins(new Set(data.vins));
    } catch (error) {
      console.error('Failed to fetch Carfax VINs:', error);
    }
  };

  useEffect(() => {
    if (!authToken) {
      console.error('No auth token found');
      return;
    }

    const loadData = async () => {
      await loadCars();
      await fetchCarfaxVins();
    };

    loadData();
  }, [authToken]);

  const handleDeleteCar = async (carId) => {
    if (!authToken) {
      console.error('No auth token found');
      return;
    }

    try {
      await deleteCar(carId, authToken);
      setCars(cars.filter(car => car.id !== carId));
    } catch (error) {
      console.error('Failed to delete car', error);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedCars = [...filteredCars].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setFilteredCars(sortedCars);
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const calculateDaysAgo = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = currentDate - createdDate;
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysAgo === 0 ? "Today" : `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  };

  const handleFilterChange = (event, key) => {
    setFilters({ ...filters, [key]: event.target.value });
  };

  const applyFilters = () => {
    const filtered = cars.filter(car =>
      (filters.make ? car.make === filters.make : true) &&
      (filters.model ? car.model === filters.model : true) &&
      (filters.year ? car.year === filters.year : true) &&
      (filters.exterior_color ? car.exterior_color === filters.exterior_color : true) &&
      (filters.interior_color ? car.interior_color === filters.interior_color : true) &&
      (filters.vin ? car.vin === filters.vin : true)
    );
    setFilteredCars(filtered);
  };

  const getUniqueValues = (key) => {
    return [...new Set(cars.map(car => car[key]))].filter(Boolean);
  };

  return (
    <div className="dashboard-container">
      <h2>Your Saved Cars</h2>

      <div className="filters">
        <label>
          Make:
          <select value={filters.make} onChange={(e) => handleFilterChange(e, 'make')}>
            <option value="">All</option>
            {getUniqueValues('make').map((make) => (
              <option key={make} value={make}>{make}</option>
            ))}
          </select>
        </label>

        <label>
          Model:
          <select value={filters.model} onChange={(e) => handleFilterChange(e, 'model')}>
            <option value="">All</option>
            {getUniqueValues('model').map((model) => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
        </label>

        <label>
          Year:
          <select value={filters.year} onChange={(e) => handleFilterChange(e, 'year')}>
            <option value="">All</option>
            {getUniqueValues('year').map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </label>

        <label>
          Exterior Color:
          <select value={filters.exterior_color} onChange={(e) => handleFilterChange(e, 'exterior_color')}>
            <option value="">All</option>
            {getUniqueValues('exterior_color').map((color) => (
              <option key={color} value={color}>{color}</option>
            ))}
          </select>
        </label>

        <label>
          Interior Color:
          <select value={filters.interior_color} onChange={(e) => handleFilterChange(e, 'interior_color')}>
            <option value="">All</option>
            {getUniqueValues('interior_color').map((color) => (
              <option key={color} value={color}>{color}</option>
            ))}
          </select>
        </label>

        <label>
          VIN:
          <select value={filters.vin} onChange={(e) => handleFilterChange(e, 'vin')}>
            <option value="">All</option>
            {getUniqueValues('vin').map((vin) => (
              <option key={vin} value={vin}>{vin}</option>
            ))}
          </select>
        </label>
      </div>

      {filteredCars.length > 0 ? (
        <div className="dashboard-table-container">
          <table className="car-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('url')}>Car{getSortIndicator('url')}</th>
                <th onClick={() => handleSort('make')}>Make{getSortIndicator('make')}</th>
                <th onClick={() => handleSort('model')}>Model{getSortIndicator('model')}</th>
                <th onClick={() => handleSort('year')}>Year{getSortIndicator('year')}</th>
                <th onClick={() => handleSort('price')}>Price{getSortIndicator('price')}</th>
                <th onClick={() => handleSort('mileage')}>Mileage{getSortIndicator('mileage')}</th>
                <th onClick={() => handleSort('exterior_color')}>Exterior{getSortIndicator('exterior_color')}</th>
                <th onClick={() => handleSort('interior_color')}>Interior{getSortIndicator('interior_color')}</th>
                <th onClick={() => handleSort('vin')}>VIN{getSortIndicator('vin')}</th>
                {/*                <th onClick={() => handleSort('isAvailable')}>Availability{getSortIndicator('isAvailable')}</th> */}
                <th onClick={() => handleSort('created_at')}>Date Added{getSortIndicator('created_at')}</th>
                <th>Carfax Report</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCars.map((car, index) => (
                <tr
                  key={index}
                  data-availability={
                    car.isAvailable === 'checking'
                      ? "checking"
                      : car.isAvailable
                        ? "Available"
                        : "Not Available"
                  }
                >
                  <td>
                    {car.url ? (
                      <a
                        href={car.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="View Car Details"
                      >
                        {getSiteFromUrl(car.url)}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{car.make}</td>
                  <td>{car.model}</td>
                  <td>{car.year}</td>
                  <td>${car.price?.toLocaleString() ?? "N/A"}</td>
                  <td>{car.mileage ? car.mileage.toLocaleString() : "N/A"}</td>
                  <td>{car.exterior_color || "N/A"}</td>
                  <td>{car.interior_color || "N/A"}</td>
                  <td>{car.vin || "N/A"}</td>
                  {/*                  <td className="availability-cell" data-status="Available">
                    Available
                  </td> */}
                  <td>{calculateDaysAgo(car.created_at)}</td>
                  <td>
                    {car.vin && carfaxVins.has(car.vin) ? (
                      <Link
                        to={`/carfax-analysis/${car.vin}`}
                        className="view-analysis-button"
                        style={{
                          padding: '6px 12px',
                          backgroundColor: '#4CAF50',
                          color: 'white',
                          textDecoration: 'none',
                          borderRadius: '4px',
                          fontSize: '0.9rem'
                        }}
                      >
                        View Report
                      </Link>
                    ) : (
                      <span style={{ color: '#666', fontSize: '0.9rem' }}>
                        {car.vin ? (
                          <>No Report {/* Add debugging */}
                            <span style={{ fontSize: '0.8rem', color: '#999' }}>
                              (VIN: {car.vin})
                            </span>
                          </>
                        ) : 'No VIN'}
                      </span>
                    )}
                  </td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteCar(car.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No cars available</p>
      )}
    </div>
  );
}

export default Dashboard;