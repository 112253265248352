import { useEffect, useRef } from "react";
import { useAuthInfo } from "@propelauth/react";

// API base URL for making authenticated requests
const API_BASE_URL = process.env.REACT_APP_PROD_SERVER_URL;

// Function to handle authentication and store the token locally
function authenticate(accessToken) {
  console.log("Authenticating with access token:", accessToken);
  sendTokenToExtension(accessToken);
  if (!accessToken) {
    console.error("Access token is null, authentication cannot proceed.");
    return Promise.reject("Access token is null");
  }

  return fetch(`${API_BASE_URL}/auth/api/authenticate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      localStorage.setItem("jwtToken", accessToken);
      return response.json();
    } else {
      return { status: response.status };
    }
  });
}

function AuthenticatedRequest() {
  const { isLoggedIn, accessToken } = useAuthInfo();
  const lastRunTimeRef = useRef(0);
  const lastTokenRef = useRef(null);

  useEffect(() => {
    const currentTime = Date.now();
    const timeSinceLastRun = currentTime - lastRunTimeRef.current;
    const TOKEN_EXPIRY_TIME = 3600000; // 1 hour in milliseconds

    if (
      isLoggedIn &&
      accessToken &&
      (accessToken !== lastTokenRef.current ||
        timeSinceLastRun > TOKEN_EXPIRY_TIME)
    ) {
      console.log("AccessToken:", accessToken);
      console.log("isLoggedIn:", isLoggedIn);
      authenticate(accessToken)
        .then((response) => {
          console.log("Authentication response:", response);
        })
        .catch((error) => {
          console.error("Authentication error:", error);
        });
      lastRunTimeRef.current = currentTime;
      lastTokenRef.current = accessToken;
    } else if (!isLoggedIn || !accessToken) {
      console.error("User not logged in or access token missing");
    }
  }, [isLoggedIn, accessToken]);

  return null; // This component doesn't render anything
}

function sendTokenToExtension(token) {
  const environment = process.env.NODE_ENV;
  const message = {
    type: "FROM_PAGE_TO_EXTENSION",
    token: token,
    environment: environment,
  };

  window.postMessage(message, "*");

  console.log("Message sent to extension:", JSON.stringify(message, null, 2));
  console.log(`Environment: ${environment}`);
}

// Log the current JWT token stored in localStorage
console.log("Access Token in LocalStorage:", localStorage.getItem("jwtToken"));

export default AuthenticatedRequest;
